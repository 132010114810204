import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";


import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "./App.css";
import axios from "axios";
import { useTable } from "react-table";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="tr-header">
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="tr-rows">
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const App = ({ children }) => {
  const [alerting, setAlerting] = useState(false);
  const clearAlert = () => {
    console.log("clearing alert");
    setAlerting(false);
    setAPIErrors("");
    console.log("searching: ", isSearching);
  };
  const [apiErrors, setAPIErrors] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [data, setData] = useState([]);
  const [number, setNumber] = useState();
  const [addressPurpose, setAddressPurpose] = useState();
  const [enumerationType, setEnumerationType] = useState("Individual");
  const [taxonomyDescription, setTaxonomyDescription] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [countryCode, setCountryCode] = useState();
  const [exactMatchOnly, setExactMatchOnly] = useState(false);
  // const [useFirstNameAlias, setUseFirstNameAlias] = useState(true);

  const formRef = useRef();
  const organizationInputRef = useRef();
  const taxonomyDescriptionInputRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const exactMatchOnlyInputRef = useRef();
  const scollToRef = useRef();
  // const useFirstNameAliasInputRef = useRef();

  const resetFormNamesOnly = () => {
    lastNameInputRef.current.value = null;
    firstNameInputRef.current.value = null;
    organizationInputRef.current.value = null;
    taxonomyDescriptionInputRef.current.value = null;
    setOrganizationName();
    setFirstName();
    setLastName();
    setTaxonomyDescription();
  };
  const resetForm = () => {
    console.log("reset", exactMatchOnly);
    setAlerting(false);
    setAPIErrors([]);
    setIsSearching(false);
    setData([]);
    setNumber();
    setAddressPurpose();
    setTaxonomyDescription();
    setCity();
    setState();
    setPostalCode();
    setCountryCode();
    setExactMatchOnly(false);
    // setUseFirstNameAlias(true);
    resetFormNamesOnly();
  };
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      setAlerting(false);
      setIsSearching(true);
      scollToRef.current.scrollIntoView();
    }
  };
  const changeHandler = (e) => {
    console.log("handling event: ", e);
    if (e && e.target) {
      console.log("targetValue", e.target.value);
      switch (e.target.name) {
        case "addressPurpose":
          setAddressPurpose(e.target.value);
          break;
        case "number":
          setNumber(e.target.value);
          break;
        case "taxonomyDescription":
          setTaxonomyDescription(e.target.value);
          break;
        case "firstName":
          setFirstName(e.target.value);
          break;
        case "lastName":
          setLastName(e.target.value);
          break;
        case "organizationName":
          setOrganizationName(e.target.value);
          break;
        case "city":
          setCity(e.target.value);
          break;
        case "state":
          setState(e.target.value);
          setAlerting(false);
          setIsSearching(true);
          scollToRef.current.scrollIntoView();

          break;
        case "postalCode":
          setPostalCode(e.target.value);
          break;
        case "country":
          setCountryCode(e.target.value);
          setAlerting(false);
          setIsSearching(true);
          scollToRef.current.scrollIntoView();

          break;
        case "exactMatchOnly":
          console.log(exactMatchOnly);
          setExactMatchOnly(!exactMatchOnly);
          // if (exactMatchOnly && useFirstNameAlias) setUseFirstNameAlias(false);
          setAlerting(false);
          break;
        // case "useFirstNameAlias":
        //   setUseFirstNameAlias(!useFirstNameAlias);
        //   if (!exactMatchOnly && !useFirstNameAlias) setExactMatchOnly(true);
        //   setAlerting(false);
        //   break;
        case "submit":
          setAlerting(false);
          setIsSearching(true);
          scollToRef.current.scrollIntoView();
          break;
        case "clear":
          resetForm();

          break;
        default:
          console.log("event intentionally ignored");
          break;
      }
    } else {
      resetFormNamesOnly();
      console.log(enumerationType);
      setEnumerationType(e[1]);
      console.log(enumerationType);
    }
  };
  const renderTooltip = (props) => {
    var tip = "Organization Name (LBN, DBA, Former LBN or Other Name)";
    return (
      <Tooltip id="button-tooltip" {...props}>
        {tip}
      </Tooltip>
    );
  };
  useEffect(() => {
    if (isSearching) {
      console.log(exactMatchOnly);
      var wildcard = exactMatchOnly ? "" : "*";
      console.log(wildcard);
      var apiParameters = "";
      apiParameters =
        enumerationType !== "NPI Number"
          ? apiParameters +
            "enumeration_type=" +
            (enumerationType === "Individual" ? "NPI-1" : "NPI-2") +
            "&"
          : apiParameters;
      apiParameters = number
        ? apiParameters + "number=" + number + "&"
        : apiParameters;
      apiParameters = firstName
        ? apiParameters + "first_name=" + firstName + wildcard + "&"
        : apiParameters;
      apiParameters = lastName
        ? apiParameters + "last_name=" + lastName + wildcard + "&"
        : apiParameters;
      apiParameters = organizationName
        ? apiParameters +
          "organization_name=" +
          organizationName +
          wildcard +
          "&"
        : apiParameters;
      apiParameters = city
        ? apiParameters + "city=" + city + wildcard + "&"
        : apiParameters;
      apiParameters = state
        ? apiParameters + "state=" + state + "&"
        : apiParameters;
      apiParameters = postalCode
        ? apiParameters + "postal_code=" + postalCode + "&"
        : apiParameters;
      apiParameters = countryCode
        ? apiParameters + "country_code=" + countryCode + "&"
        : apiParameters;
      // WEIRD PARAMETERS -- FIXME
      apiParameters = addressPurpose
        ? apiParameters + "address_purpose=" + addressPurpose + wildcard + "&"
        : apiParameters;

      apiParameters = taxonomyDescription
        ? apiParameters + "taxonomy_description=" + taxonomyDescription + "&"
        : apiParameters;
      // apiParameters = useFirstNameAlias
      //   ? apiParameters +
      //     "use_first_name_alias=" +
      //     (useFirstNameAlias ? "True" : "False") +
      //     "&"
      //   : apiParameters;
      console.log("checking api params");
      if (!apiParameters) {
        console.log("empty api params");
        setAPIErrors("You didn't search for anything.");
        setAlerting(true);
        setIsSearching(false);
      } else {
        var apiURL =
          "https://npi.coupadata.com/api/?version=2.1&limit=200&" +
          apiParameters;
        console.log("CALLING API! ", apiURL);
        setIsSearching(true);
        scollToRef.current.scrollIntoView();

        axios
          .get(apiURL)
          .then((res) => {
            setIsSearching(false);
            if (res.data["results"]) setData(res.data["results"]);
            else if (res.data["Errors"]) {
              setAPIErrors(
                res.data["Errors"][0]["field"] +
                  ": " +
                  res.data["Errors"][0]["description"]
              );
              setAlerting(true);
            } else setData([]);
          })
          .catch((err) => console.log(err));
      }
    } // some comment
  }, [
    isSearching,
    addressPurpose,
    number,
    enumerationType,
    taxonomyDescription,
    firstName,
    // useFirstNameAlias,
    lastName,
    organizationName,
    city,
    state,
    postalCode,
    countryCode,
    exactMatchOnly,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "NPI",
        accessor: "number",
        Cell: ({ cell: { value } }) => {
          try {
            if (value) {
              var href =
                "https://npiregistry.cms.hhs.gov/provider-view/" + value;
              return (
                <div>
                  <a target="_blank" rel="noopener noreferrer" href={href}>
                    {value}
                  </a>
                </div>
              );
            } else {
              return <div></div>;
            }
          } catch (e) {
            return <div></div>;
          }
        },
      },
      {
        Header: "Name",
        accessor: "basic",
        Cell: ({ cell: { value } }) => {
          try {
            if (value && value.first_name)
              return (
                <div>
                  {value.first_name} {value.last_name}
                </div>
              );
            else if (value && value.organization_name)
              return <div>{value.organization_name}</div>;
            else {
              return <div></div>;
            }
          } catch (e) {
            return <div></div>;
          }
        },
      },
      {
        Header: "Address",
        accessor: "addresses",
        Cell: ({ cell: { value } }) => {
          try {
            if (value) {
              var addresses = value;
              addresses = addresses.filter(function (item) {
                return item.address_purpose === "LOCATION";
              });
              var address = addresses[0];
              var postal_code = address.postal_code;
              if (postal_code && address.address_type === "DOM")
                postal_code = postal_code.substring(0, 5);

              //        address = JSON.stringify(address);
              return (
                <>
                  <div>{address.address_1}</div>
                  <div>
                    {address.city}, {address.state}&nbsp;&nbsp;{postal_code}
                  </div>
                  <div>{address.country_name}</div>
                  <div>{address.telephone_number}</div>
                </>
              );
            } else {
              return <div></div>;
            }
          } catch (e) {
            return <div></div>;
          }
        },
      },
      {
        Header: "Primary Taxonomy",
        accessor: "taxonomies",
        Cell: ({ cell: { value } }) => {
          try {
            if (value) {
              var taxonomies = value;
              var taxonomy;
              taxonomies = taxonomies.filter(function (item) {
                return item.primary === true;
              });
              if (taxonomies[0]) taxonomy = taxonomies[0].desc;
              return (
                <>
                  <div>{taxonomy}</div>
                </>
              );
            } else {
              return <div></div>;
            }
          } catch (e) {
            return <div></div>;
          }
        },
      },
      {
        Header: "Individual or Organization?",
        accessor: "enumeration_type",
        Cell: ({ cell: { value } }) => {
          try {
            if (value) {
              return (
                <>
                  <div>{value === "NPI-1" ? "Individual" : "Organization"}</div>
                </>
              );
            } else {
              return <div></div>;
            }
          } catch (e) {
            return <div></div>;
          }
        },
      },
    ],
    []
  );

  return (
    <>
      <Container fluid="md auto" className="p-5 mb-4 bg-light rounded-3">
        <Modal show={alerting} onHide={clearAlert}>
          <Modal.Header>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{apiErrors}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={clearAlert}>
              Try Again
            </Button>
          </Modal.Footer>
        </Modal>
        <h1 className="header">NPI Record Search</h1>
        <ToggleButtonGroup
          name="searchMode"
          type="checkbox"
          value={enumerationType}
          onChange={(e) => {
            changeHandler(e);
          }}
        >
          <ToggleButton id="individual" value={"Individual"}>
            Individual
          </ToggleButton>
          <ToggleButton id="organization" value={"Organization"}>
            Organization
          </ToggleButton>
          <ToggleButton id="npinumber" value={"NPI Number"}>
            NPI Number
          </ToggleButton>
        </ToggleButtonGroup>
        <Form ref={formRef}>
          <Row>
            <Col
              xs="auto"
              className={enumerationType === "NPI Number" ? "" : "d-none"}
            >
              <Form.Group className="mb-3">
                <FloatingLabel className="mb-3" label="NPI Number">
                  <Form.Control
                    name="number"
                    type="number"
                    placeholder=" "
                    defaultValue={number}
                    onChange={changeHandler}
                    onKeyDown={keyHandler}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col
              xs="auto"
              className={enumerationType === "Individual" ? "" : "d-none"}
            >
              <Form.Group className="mb-3">
                <FloatingLabel label="First Name">
                  <Form.Control
                    ref={firstNameInputRef}
                    name="firstName"
                    type="text"
                    placeholder=" "
                    defaultValue={firstName}
                    onChange={changeHandler}
                    onKeyDown={keyHandler}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col
              xs="auto"
              className={enumerationType === "Individual" ? "" : "d-none"}
            >
              <Form.Group className="mb-3">
                <FloatingLabel label="Last Name">
                  <Form.Control
                    ref={lastNameInputRef}
                    name="lastName"
                    type="text"
                    placeholder=" "
                    defaultValue={lastName}
                    onChange={changeHandler}
                    onKeyDown={keyHandler}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col
              xs="auto"
              className={enumerationType === "Organization" ? "" : "d-none"}
            >
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Form.Group className="mb-3">
                  <FloatingLabel label="Organization Name">
                    <Form.Control
                      ref={organizationInputRef}
                      name="organizationName"
                      type="text"
                      placeholder=" "
                      defaultValue={organizationName}
                      onChange={changeHandler}
                      onKeyDown={keyHandler}
                    />
                  </FloatingLabel>
                </Form.Group>
              </OverlayTrigger>
            </Col>
            <Col
              xs="auto"
              className={enumerationType === "Organization" ? "" : "d-none"}
            >
              <Form.Group className="mb-3">
                <FloatingLabel label="Taxonomy Description">
                  <Form.Control
                    ref={taxonomyDescriptionInputRef}
                    name="taxonomyDescription"
                    type="text"
                    placeholder=" "
                    defaultValue={taxonomyDescription}
                    onChange={changeHandler}
                    onKeyDown={keyHandler}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col
              xs="auto"
              className={enumerationType !== "NPI Number" ? "" : "d-none"}
            >
              <Form.Group className="mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    name="city"
                    type="text"
                    placeholder=" "
                    defaultValue={city}
                    onChange={changeHandler}
                    onKeyDown={keyHandler}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col
              xs="auto"
              className={enumerationType !== "NPI Number" ? "" : "d-none"}
            >
              <FloatingLabel label="State">
                <Form.Select
                  name="state"
                  aria-label="NPIState"
                  onChange={changeHandler}
                  onKeyDown={keyHandler}
                  defaultValue={""}
                >
                  <option value="">Any</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AS">American Samoa</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="AA">Armed Forces America</option>
                  <option value="AE">
                    Armed Forces Europe /Canada / Middle East / Africa
                  </option>
                  <option value="AP">Armed Forces Pacific</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District of Columbia</option>
                  <option value="FM">Federated States of Micronesia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="GU">Guam</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MP">Mariana Islands, Northern</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VI">Virgin islands</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col
              xs="auto"
              className={enumerationType !== "NPI Number" ? "" : "d-none"}
            >
              <FloatingLabel label="Country">
                <Form.Select
                  name="country"
                  aria-label="NPICountry"
                  onChange={changeHandler}
                  onKeyDown={keyHandler}
                  defaultValue={""}
                >
                  <option value="">Any</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">
                    Congo, The Democratic Republic of the
                  </option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croatia</option>
                  <option value="CI">Ctte DIvoire</option>
                  <option value="CU">Cuba</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="AX">Eland Islands</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GB">Great Britain</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle Of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">
                    Korea, Democratic Peoples Republic of
                  </option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="XK">Kosovo</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao Peoples Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="SH">Saint Helena</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="CS">Serbia and Montenegro</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="US">United States</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col
              xs="auto"
              className={enumerationType !== "NPI Number" ? "" : "d-none"}
            >
              <Form.Group className="mb-3">
                <FloatingLabel label="Postal Code">
                  <Form.Control
                    name="postalCode"
                    type="text"
                    placeholder=" "
                    defaultValue={postalCode}
                    onChange={changeHandler}
                    onKeyDown={keyHandler}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            {/* <Col
              xs="auto"
              className={enumerationType !== "NPI Number" ? "" : "d-none"}
            >
              <FloatingLabel label="Address Type">
                <Form.Select
                  name="addressType"
                  aria-label="AddressType"
                  onChange={changeHandler}
                  onKeyDown={keyHandler}
                  defaultValue={addressPurpose}
                >
                  <option value="">Any</option>
                  <option value="PR">Primary Location</option>
                  <option value="SE">Secondary Location</option>
                </Form.Select>
              </FloatingLabel>
            </Col> */}
          </Row>
          <Row>
            <Col
              xs="auto"
              className={enumerationType !== "NPI Number" ? "" : "d-none"}
            >
              <Form.Check
                ref={exactMatchOnlyInputRef}
                name="exactMatchOnly"
                type="switch"
                id="exactmatch"
                label="Check this to search for Exact Matches only E.g., First Name=Ro will no longer return Providers with the first name of Robert and Ronald.  City of Overland will no longer return Overland Park."
                onChange={changeHandler}
                onKeyDown={keyHandler}
                checked={exactMatchOnly}
              />
            </Col>
            <Col
              xs="auto"
              className={enumerationType === "Individual" ? "" : "d-none"}
            >
              {/* <Form.Check
                ref={useFirstNameAliasInputRef}
                name="useFirstNameAlias"
                type="switch"
                id="usefirstnamealias"
                label="Check this box to search for first name aliases E.g., first_name=Robert, will also return Providers with the first name of Rob, Bob, Robbie, Bobby, etc."
                onChange={changeHandler}
                onKeyDown={keyHandler}
                checked={useFirstNameAlias}
              /> */}
            </Col>
          </Row>
          <div ref={scollToRef}>
            <Button variant="primary" onClick={changeHandler} name="submit">
              Submit
            </Button>
            <Button variant="secondary" onClick={changeHandler} name="clear">
              Clear
            </Button>
          </div>
        </Form>
        Please Note: Issuance of an NPI does not ensure or validate that the
        Health Care Provider is Licensed or Credentialed. For more information
        please refer to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/Downloads/NPI-What-You-Need-To-Know.pdf"
        >
          NPI: What You Need to Know
        </a>
      </Container>
      <div className="p-5 mb-4 bg-light rounded-3">
        <h1 className="header">Search Results </h1>
        <Table columns={columns} data={data} />
      </div>
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};
export default App;
